import {
  HomepageOptionsResponse,
  HomepagePropertySearchRequest,
  HomepagePropertySearchResponse
} from 'homepage/models';
import { createAction, props } from '@ngrx/store';
import { Pagination } from '@ui/shared/models';
import { LocationSearchResultEntries } from '@ui/legacy-lib';

export const searchProperties = createAction(
  '[HomepageProperties] Search',
  props<{ propertySearchInput: HomepagePropertySearchRequest }>()
);

export const searchPropertiesSuccess = createAction(
  '[HomepageProperties] Search Success',
  props<{
    propertySearchOutput: HomepagePropertySearchResponse[];
    pagination: Pagination;
  }>()
);

export const searchPropertiesFailure = createAction(
  '[HomepageProperties] Search Failure',
  props<{ error: string }>()
);

export const updateProperties = createAction(
  '[HomepageProperties] Update Success',
  props<{
    propertySearchOutput: HomepagePropertySearchResponse[];
    pagination: Pagination;
  }>()
);

export const getHomepageOptions = createAction(
  '[HomepageProperties] Options',
  props<{ token: string }>()
);

export const getHomepageOptionsSuccess = createAction(
  '[HomepageProperties] Options Success',
  props<{
    homepageOptionsResponse: HomepageOptionsResponse;
  }>()
);

export const getHomepageOptionsFailure = createAction(
  '[HomepageProperties] Options Failure',
  props<{ error: string }>()
);

export const locationSearch = createAction(
  '[HomepageProperties] Location Search',
  props<{ name: string }>()
);

export const locationSearchSuccess = createAction(
  '[HomepageProperties] Location Search Success',
  props<{
    locationSearchResultEntries: LocationSearchResultEntries;
  }>()
);

export const locationSearchFailure = createAction(
  '[HomepageProperties] Location Search Failure',
  props<{ error: string }>()
);

export const reset = createAction('[HomepageProperties] Reset');

export const drawMapRadius = createAction(
  '[HomepageProperties] Draw Map Radius',
  props<{ mapRadiusDrawInfo: [number, number, number] }>()
);

export const setTileServerUrl = createAction(
  '[HomepageProperties] Set Tile Server Url',
  props<{ tileServerUrl: string }>()
);

export const setTileServerUrlSuccess = createAction(
  '[HomepageProperties] Set Tile Server Url Success',
  props<{ tileServerUrl: string }>()
);
