import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { UntilDestroy } from '@ngneat/until-destroy';
import { v4 as uuid } from 'uuid';

import {
  BadgeColorEnum,
  BadgeComponent,
  ButtonComponent,
  DataTableCellComponent,
  DataTableCellDirective,
  DataTableColumn,
  DataTableComponent,
  isTouchScreen,
  SCREEN_LARGE_MIN
} from '@ui/legacy-lib';

import {
  HomepageOptionsResponse,
  HomepagePropertySearchResponse,
  HomepagePropertyStatusType
} from 'homepage/models';
import { CurrencyPipe, DecimalPipe, NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { propertiesTableSvgConfig } from './properties-table.config';

@UntilDestroy()
@Component({
  selector: 'app-properties-table',
  templateUrl: './properties-table.component.html',
  styleUrls: ['./properties-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DataTableComponent,
    DataTableCellComponent,
    DecimalPipe,
    DataTableCellDirective,
    BadgeComponent,
    CurrencyPipe,
    TranslateModule,
    ButtonComponent,
    NgClass
  ],
  animations: [
    trigger('openCloseSvgContainer', [
      state(
        'open',
        style({
          opacity: 1
        })
      ),
      state(
        'closed',
        style({
          opacity: 0
        })
      ),
      transition('open => closed', [animate('.3s')]),
      transition('closed => open', [animate('1s')])
    ])
  ]
})
export class PropertiesTableComponent implements OnInit, AfterViewInit {
  private sanitizer = inject(DomSanitizer);

  @Input() options: HomepageOptionsResponse;
  @Input() isLoading: boolean;
  @Input() properties: HomepagePropertySearchResponse[];
  @Input() customerId: number;
  @ViewChild('svgContainerList') svgContainerList: ElementRef;
  @ViewChild('table') table: ElementRef;
  public svgDataList: SafeHtml[] = [];
  public columns: DataTableColumn[] = [
    { name: 'objectId', label: 'general.table_col_id' },
    { name: 'etage', label: 'general.table_col_etage' },
    { name: 'totalRooms', label: 'general.table_col_rooms' },
    { name: 'size', label: 'general.table_col_size' },
    {
      name: 'totalRentGross',
      label: 'general.table_col_rent'
    },
    {
      name: 'status',
      label: 'general.table_col_status'
    },
    { name: 'wbs', label: 'general.table_col_wbs' },
    { name: 'expose' }
  ];

  public isTouchScreen = isTouchScreen();
  public selectedPropertyId: string;
  public selectedSvgContainerIndex = 0;

  private svgConfig = propertiesTableSvgConfig;
  private svgContainers: HTMLElement[];
  private svgBuildings: HTMLElement[];
  private svgLevels: HTMLElement[];
  private svgProperties: HTMLElement[];

  public get show3dVisualisation(): boolean {
    return !!this.svgDataList.length;
  }

  ngOnInit() {
    /* SVG non-test injection (on activation: deactivate test injection) - START */

    this.svgDataList = this.options?.homepageModuleSVGs?.map(svgData =>
      this.sanitizer.bypassSecurityTrustHtml(svgData.svgAsXmlString)
    );

    /* SVG non-test injection - END */

    // ---

    /* SVG test injection (on activation: deactivate non-test injection) - START */

    // this.svgTestService.loadSvgs(
    //   [
    //     // first SVG should represent an overview if available
    //     // => if not: provide withOverviewSvg=false for mockExternalIdSvgPropertyMapping() below
    //     '/assets/images/svg-test/overview.svg',
    //     '/assets/images/svg-test/building1.svg',
    //     '/assets/images/svg-test/building2.svg'
    //
    //     // check for test SVGs here:
    //     // https://immomio.atlassian.net/wiki/spaces/EB/pages/190251103/3D+Model+Manufacturing#Test-SVGs
    //   ],
    //   svgList => {
    //     this.svgDataList = svgList.map(svg =>
    //       this.sanitizer.bypassSecurityTrustHtml(svg)
    //     );
    //
    //     setTimeout(() => {
    //       // auto mock map SVG properties to available external id list:
    //       this.svgTestService.mockExternalIdSvgPropertyMapping(
    //         this.svgContainerList,
    //         this.properties.map(property => property.externalId)
    //       );
    //
    //       this.initialise3dVisualisation();
    //     }, 500);
    //   }
    // );

    /* SVG test injection - END */
  }

  public ngAfterViewInit(): void {
    if (!this.svgDataList.length) return;

    this.initialise3dVisualisation();
  }

  public getStatusBadgeColor(
    status: HomepagePropertyStatusType
  ): BadgeColorEnum {
    if (status == HomepagePropertyStatusType.RENTED) {
      return BadgeColorEnum.STATE_900;
    } else if (status == HomepagePropertyStatusType.RESERVED) {
      return BadgeColorEnum.STATE_500;
    } else {
      return BadgeColorEnum.STATE_100;
    }
  }

  public setSvgPropertyStatusClass(
    propertyElement: HTMLElement,
    status?: HomepagePropertyStatusType
  ): void {
    if (!propertyElement) return;
    const newStatusClass = this.getSvgPropertyStatusClass(status);

    // remove status classes which do not match the new status:
    Object.values(this.svgConfig.class.propertyStatus)
      .filter(
        statusClass =>
          propertyElement.classList.contains(statusClass) &&
          statusClass !== newStatusClass
      )
      .forEach(statusClass => {
        propertyElement.classList.remove(statusClass);
      });

    // add the new status class:
    propertyElement.classList.add(newStatusClass);
  }

  public getSvgPropertyStatusClass(status: HomepagePropertyStatusType): string {
    const customColorCustomerId = 254980691;
    /*
      https://immomio.atlassian.net/browse/ART-8882?atlOrigin=eyJpIjoiOGIzMDJkYzg2NDgzNDYxNWFkZGQ3MTMzZTY3YTMwN2MiLCJwIjoiaiJ9
      Request has been made to change the highlight color for the customer with the id 254980691.
      TODO: change name of the custom class to incremental or append customerID if another request comes in.
    */
    if (this.customerId !== customColorCustomerId) {
      if (status == HomepagePropertyStatusType.RENTED) {
        return this.svgConfig.class.propertyStatus.rented;
      } else if (status == HomepagePropertyStatusType.RESERVED) {
        return this.svgConfig.class.propertyStatus.reserved;
      } else {
        return this.svgConfig.class.propertyStatus.free;
      }
    }
    return this.svgConfig.class.propertyStatus.custom;
  }

  public getEncodedId(id: string): string {
    return encodeURIComponent(id);
  }

  public initialise3dVisualisation() {
    if (!this.svgContainerList) return;
    const { propertyId: propertyIdAttr, svgLink: svgLinkAttr } =
      this.svgConfig.attr;
    const svgContainerList = this.svgContainerList.nativeElement as HTMLElement;

    this.svgContainers = Array.from(
      svgContainerList.querySelectorAll(this.svgConfig.querySelector.container)
    );
    this.svgBuildings = Array.from(
      svgContainerList.querySelectorAll(this.svgConfig.querySelector.building)
    );
    this.svgLevels = Array.from(
      svgContainerList.querySelectorAll(this.svgConfig.querySelector.level)
    );
    this.svgProperties = Array.from(
      svgContainerList.querySelectorAll(this.svgConfig.querySelector.property)
    );

    // add event listeners for all svg properties:
    this.svgProperties.forEach(property => {
      let propertyId = property.getAttribute(propertyIdAttr);
      if (!propertyId) {
        // set uuid as id if id attribute is not set for property
        // => this ensures proper highlighting in the svg even if properties are not matching the table rows
        propertyId = uuid();
        property.setAttribute(propertyIdAttr, propertyId);
      }

      property.addEventListener(
        'click',
        () => {
          this.onClickSvgProperty(propertyId);
        },
        true
      );

      if (!this.isTouchScreen) {
        property.addEventListener(
          'mouseenter',
          () => {
            this.selectProperty(propertyId);
          },
          true
        );
      }
    });

    // add event listeners for svg links:
    const svgLinks = Array.from(
      svgContainerList.querySelectorAll(`[${svgLinkAttr}]`)
    );
    svgLinks.forEach(link => {
      const targetSvgId = link.getAttribute(svgLinkAttr);

      const targetContainer: HTMLElement = svgContainerList
        .querySelector(`#${targetSvgId}`)
        ?.closest(this.svgConfig.querySelector.container);

      link.addEventListener(
        'click',
        () => {
          this.selectProperty();
          this.activateSvgContainer(targetContainer);
        },
        true
      );
    });
  }

  public activateSvgContainer(targetContainer?: HTMLElement): void {
    if (!targetContainer) targetContainer = this.svgContainers[0];

    const targetContainerIndex = this.svgContainers.indexOf(targetContainer);
    if (targetContainerIndex === this.selectedSvgContainerIndex) return;

    this.selectedSvgContainerIndex = targetContainerIndex;
  }

  public hideNextSvgBuilding(targetBuilding: HTMLElement) {
    const { hiddenBuilding: hiddenBuildingClass } = this.svgConfig.class;
    const nextBuilding = targetBuilding?.nextElementSibling as HTMLElement;

    // hide given next building:
    nextBuilding?.classList.add(hiddenBuildingClass);

    // unhide hidden buildings not equal to the given next building:
    this.svgBuildings
      .filter(
        building =>
          building.classList.contains(hiddenBuildingClass) &&
          building !== nextBuilding
      )
      .forEach(building => building.classList.remove(hiddenBuildingClass));
  }

  public elevateUpperSvgLevels(targetLevel: HTMLElement) {
    const {
      class: { elevatedLevel: elevatedLevelClass },
      querySelector: { level: levelQuerySelector }
    } = this.svgConfig;
    const upperLevels: HTMLElement[] = targetLevel
      ? this.nextAllElements(targetLevel, levelQuerySelector)
      : [];

    // elevate all levels above of given level:
    upperLevels.forEach(upperLevel =>
      upperLevel.classList.add(elevatedLevelClass)
    );

    // bring down elevated levels not equal to the levels above the given level:
    this.svgLevels
      .filter(
        level =>
          level.classList.contains(elevatedLevelClass) &&
          !upperLevels.includes(level)
      )
      .forEach(level => level.classList.remove(elevatedLevelClass));
  }

  public setActiveSvgProperty(propertyId?: string) {
    if (!this.svgProperties) return;

    const {
      attr: { propertyId: propertyIdAttr },
      querySelector,
      class: { activeProperty: activePropertyClass }
    } = this.svgConfig;

    // get target property to highlight by given property id:
    const targetProperty = this.svgProperties.find(
      property => property.getAttribute(propertyIdAttr) === propertyId
    );

    const propertyData = this.getPropertyData(propertyId);

    // highlight target property if it's data is loaded too (& it appears in the table):
    if (propertyData) targetProperty?.classList.add(activePropertyClass);

    // update status class for target property:
    this.setSvgPropertyStatusClass(targetProperty, propertyData?.status);

    // unset highlighting for properties not equal to target property:
    this.svgProperties
      .filter(
        property =>
          property.classList.contains(activePropertyClass) &&
          property !== targetProperty
      )
      .forEach(property => property.classList.remove(activePropertyClass));

    // ---

    // hide all container except of target container:
    const targetContainer: HTMLElement = targetProperty?.closest(
      querySelector.container
    );
    this.activateSvgContainer(targetContainer);

    // hide building right in front of the target building:
    const targetBuilding: HTMLElement = targetProperty?.closest(
      querySelector.building
    );
    this.hideNextSvgBuilding(targetBuilding);

    // elevate all levels above target level:
    const targetLevel: HTMLElement = targetProperty?.closest(
      querySelector.level
    );
    this.elevateUpperSvgLevels(targetLevel);
  }

  public nextAllElements(element: HTMLElement, selector?: string) {
    const nextElements: HTMLElement[] = [];
    while ((element = element.nextElementSibling as HTMLElement)) {
      if (!selector || element.matches(selector)) {
        nextElements.push(element);
      }
    }
    return nextElements;
  }

  public selectProperty(propertyId?: string, preventTableAutoScroll = false) {
    if (this.svgDataList.length && propertyId !== this.selectedPropertyId) {
      this.selectedPropertyId = propertyId;

      this.setActiveTableRow(propertyId, preventTableAutoScroll);
      this.setActiveSvgProperty(propertyId);
    }
  }

  public setActiveTableRow(
    propertyId: string,
    preventTableAutoScroll: boolean
  ) {
    const table = this.table.nativeElement as HTMLElement;
    const rowActiveClass = 'data-table__row--active';
    const targetTableRow = this.getTableRow(propertyId);

    // highlight target table row:
    targetTableRow?.classList.add(rowActiveClass);

    // scroll target table row into view if it gets not prevented by flag
    // or by the screen being that narrow, that svg and table are not showing in 50:50 view:
    if (!preventTableAutoScroll && !this.narrowScreen())
      this.scrollTableRowIntoView(targetTableRow);

    // unset highlighting for table rows not equal to target table row:
    const activeRows = Array.from(
      table?.getElementsByClassName(rowActiveClass)
    );
    activeRows
      .filter(activeRow => activeRow !== targetTableRow)
      .forEach(activeRow => {
        activeRow.classList.remove(rowActiveClass);
      });
  }

  public scrollTableRowIntoView(tableRow: Element): void {
    tableRow?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }

  public getTableRow(propertyId) {
    const innerCell = (this.table.nativeElement as HTMLElement).querySelector(
      `app-data-table-cell[data-row-id='${this.getEncodedId(propertyId)}']`
    );
    if (innerCell) {
      return innerCell.closest('.data-table__row');
    }
    return null;
  }

  public onMouseenterPropertyTableCell(propertyId: string) {
    if (!this.isTouchScreen) this.selectProperty(propertyId, true);
  }

  public onClickPropertyTableCell(property: HomepagePropertySearchResponse) {
    const { externalId, applicationLink } = property;
    if (!this.isTouchScreen || this.selectedPropertyId === externalId) {
      this.redirectToApplyPage(applicationLink);
    } else {
      this.selectProperty(externalId, true);
    }
  }

  public onClickPropertyTableButton(property: HomepagePropertySearchResponse) {
    this.redirectToApplyPage(property.applicationLink);
  }

  public getPropertyData(propertyId: string): HomepagePropertySearchResponse {
    return this.properties.find(property => property.externalId == propertyId);
  }

  public onClickSvgProperty(propertyId: string) {
    const property = this.getPropertyData(propertyId);

    if (property) {
      const isPropertyAlreadySelected = this.selectedPropertyId === propertyId;
      const narrowScreen = this.narrowScreen();

      if (isPropertyAlreadySelected) {
        if (narrowScreen) {
          this.scrollTableRowIntoView(this.getTableRow(propertyId));
        } else {
          this.redirectToApplyPage(property.applicationLink);
        }
      } else {
        const preventTableAutoScroll = narrowScreen;
        this.selectProperty(propertyId, preventTableAutoScroll);
      }
    } else {
      this.selectProperty(propertyId);
    }
  }

  public onClickSvgBackButton(): void {
    this.selectProperty();
    this.activateSvgContainer();
  }

  public redirectToApplyPage(applicationLink: string): void {
    window.open(applicationLink, '_blank');
  }

  public narrowScreen(): boolean {
    return window.innerWidth < SCREEN_LARGE_MIN;
  }
}
