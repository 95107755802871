import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import {
  BreakPointsMaxInPx,
  NameValue,
  Pagination,
  PropertyType
} from '@ui/shared/models';
import {
  AppInputDirective,
  AutofocusDirective,
  ButtonComponent,
  CheckboxComponent,
  DropdownSelectComponent,
  FormFieldComponent,
  isPropertyTypeCommercial,
  isPropertyTypeFlat,
  isPropertyTypeGarage,
  LocationSearchFieldComponent,
  LocationSearchResultEntries,
  LocationSearchResultEntry,
  LocationSearchService,
  SliderComponent
} from '@ui/legacy-lib';

import { HomepageOptionsResponse, homepageSortings } from 'homepage/models';
import * as fromPropertyListState from 'homepage/+state/property-list/property-list.reducers';
import * as fromPropertyListActions from 'homepage/+state/property-list/property-list.actions';
import { TranslateModule } from '@ngx-translate/core';
import { defaultValues } from '../../properties.component';

@UntilDestroy()
@Component({
  selector: 'app-properties-filter',
  templateUrl: './properties-filter.component.html',
  styleUrls: ['./properties-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    DropdownSelectComponent,
    TranslateModule,
    FormFieldComponent,
    LocationSearchFieldComponent,
    AppInputDirective,
    AutofocusDirective,
    SliderComponent,
    CheckboxComponent,
    ButtonComponent
  ],
  standalone: true
})
export class PropertiesFilterComponent implements OnInit {
  private fb = inject(FormBuilder);
  private store = inject(Store);
  private locationSearch = inject(LocationSearchService);
  private breakpointObserver = inject(BreakpointObserver);

  @Input() pagination: Pagination;
  @Input() isMapView: boolean;
  @Input() options: HomepageOptionsResponse;

  @Output() form = new EventEmitter<FormGroup>();
  @Output() isMap = new EventEmitter<boolean>();

  @ViewChildren(SliderComponent) sliders: SliderComponent[];
  @ViewChild(LocationSearchFieldComponent)
  locationSearchFieldComponent: LocationSearchFieldComponent;

  statusOptions: boolean;
  public filterForm: FormGroup;
  public locationSearchSelector$: Observable<LocationSearchResultEntries>;
  public selectedLocation = false;
  public maxBreakpointMD = true;

  public typesOfUse: NameValue[] = [
    { name: 'general.all_types', value: null },
    { name: 'OBJECTTYPES_FLAT', value: PropertyType.FLAT },
    { name: 'PROPERTYTYPES_GARAGE', value: PropertyType.GARAGE },
    { name: 'PROPERTYTYPES_COMMERCIAL', value: PropertyType.COMMERCIAL }
  ];

  public sortOptions: NameValue[] = [
    {
      name: 'general.sort_total_rent_gross_desc',
      value: homepageSortings.totalRentGrossDesc
    },
    {
      name: 'general.sort_total_rent_gross_asc',
      value: homepageSortings.totalRentGrossAsc
    },
    {
      name: 'general.sort_number_of_rooms_desc',
      value: homepageSortings.numberOfRoomsDesc
    },
    {
      name: 'general.sort_number_of_rooms_asc',
      value: homepageSortings.numberOfRoomsAsc
    },
    {
      name: 'general.sort_size_desc',
      value: homepageSortings.propertySizeDesc
    },
    {
      name: 'general.sort_size_asc',
      value: homepageSortings.propertySizeAsc
    },
    {
      name: 'general.sort_created_desc',
      value: homepageSortings.createdDesc
    },
    {
      name: 'general.sort_created_asc',
      value: homepageSortings.createdAsc
    },
    {
      name: 'general.sort_available_from_asc',
      value: homepageSortings.availableFromAsc
    },
    {
      name: 'general.sort_available_from_desc',
      value: homepageSortings.availableFromDesc
    }
  ];

  public radiusOptions: NameValue[] = [
    { name: '1 km', value: 1000 },
    { name: '5 km', value: 5000 },
    { name: '10 km', value: 10000 },
    { name: '25 km', value: 25000 },
    { name: '50 km', value: 50000 },
    { name: '100 km', value: 100000 },
    { name: '200 km', value: 200000 },
    { name: '500 km', value: 500000 }
  ];

  public yesNoOptions: NameValue[] = [
    { name: 'general.yes_l', value: true },
    { name: 'general.no_l', value: false }
  ];

  public get getLocationSearchControl(): FormControl {
    return this.filterForm?.get('location') as FormControl;
  }

  public get getLocationSearchControlObservable(): Observable<unknown> {
    return this.getLocationSearchControl?.valueChanges;
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(`(max-width: ${BreakPointsMaxInPx.MD}px)`)
      .pipe(untilDestroyed(this))
      .subscribe(result => (this.maxBreakpointMD = result.matches));

    this.filterForm = this.fb.group({
      typeOfUse: [null],
      typeOfPlaces: [''],
      searchByID: [''],
      balconyTerrace: [],
      wbs: [],
      barrierFree: [],
      typeOfObject: [''],
      location: [''],
      propertySize: [0],
      numberOfRooms: [0],
      totalRentGross: [defaultValues.totalRent],
      floor: [defaultValues.floor],
      sortOption: [homepageSortings.createdDesc],
      radius: [this.radiusOptions[3].value],
      lat: [],
      lon: [],
      searchResult: []
    });

    this.filterForm
      .get('searchByID')
      .valueChanges.pipe(debounceTime(1000))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.onFilterChange());

    this.filterForm
      .get('radius')
      .valueChanges.pipe(
        filter(value => !!value),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.onFilterChange();
      });

    this.locationSearchSelector$ = this.store.select(
      fromPropertyListState.getLocations
    );

    this.onFilterChange();
  }

  public onFilterChange(): void {
    this.drawMapRadius();
    this.form.emit(this.filterForm);
  }

  public drawMapRadius(): void {
    if (
      this.filterForm.get('lon').value &&
      this.filterForm.get('lat').value &&
      this.filterForm.get('radius').value
    )
      this.store.dispatch(
        fromPropertyListActions.drawMapRadius({
          mapRadiusDrawInfo: [
            this.filterForm.get('lat').value,
            this.filterForm.get('lon').value,
            this.filterForm.get('radius').value
          ]
        })
      );
  }

  public resetForm(): void {
    this.filterForm.reset();
    this.filterForm.get('propertySize').setValue(0);
    this.filterForm.get('numberOfRooms').setValue(0);
    this.filterForm.get('floor').setValue(defaultValues.floor);
    this.filterForm.get('totalRentGross').setValue(defaultValues.totalRent);
    this.filterForm.get('sortOption').setValue(homepageSortings.createdDesc);
    this.filterForm.get('radius').setValue(this.radiusOptions[3].value);
    this.locationSearchFieldComponent.resetValue();
    this.selectedLocation = false;
    this.form.emit(this.filterForm);
    this.sliders?.forEach(slider => {
      if (
        slider?.max === defaultValues.floor ||
        slider?.max === defaultValues.totalRent
      ) {
        slider.manualValueReset('max');
      } else {
        slider.manualValueReset('min');
      }
    });
    this.store.dispatch(fromPropertyListActions.reset());
    this.store.dispatch(
      fromPropertyListActions.drawMapRadius({ mapRadiusDrawInfo: null })
    );
  }

  public onLocationSelect(value: LocationSearchResultEntry): void {
    this.filterForm.get('lon').setValue(value.geometry.coordinates[0]);
    this.filterForm.get('lat').setValue(value.geometry.coordinates[1]);

    if (this.getLocationSearchControl.value) {
      this.selectedLocation = true;

      this.isMap.emit(true);
    }
    this.onFilterChange();
  }

  public changeView(value: boolean): void {
    this.isMap.emit(value);
  }

  public getAddressValue(value: LocationSearchResultEntry): string {
    return this.locationSearch.getLocationLabel(value.properties);
  }

  public get isTypeFlat(): boolean {
    return isPropertyTypeFlat(this.filterForm.get('typeOfUse').value);
  }

  public get isTypeGarage(): boolean {
    return isPropertyTypeGarage(this.filterForm.get('typeOfUse').value);
  }

  public get isTypeCommercial(): boolean {
    return isPropertyTypeCommercial(this.filterForm.get('typeOfUse').value);
  }

  public get disableNumberOfRoomsFilter(): boolean {
    return this.isTypeGarage || this.isTypeCommercial;
  }

  public get disableFloorFilter(): boolean {
    return this.isTypeGarage;
  }

  public get disableBarrierFreeFilter(): boolean {
    return this.isTypeGarage;
  }

  public get disableTerraceBalconyFilter(): boolean {
    return this.isTypeGarage;
  }

  public get disableHousingPermissionFilter(): boolean {
    return this.isTypeGarage || this.isTypeCommercial;
  }

  public get showLocationSearch(): boolean {
    return this.options?.type === 'CARD';
  }

  public get showLocationSearchContainer(): boolean {
    return !(!this.showLocationSearch && this.maxBreakpointMD);
  }
}
