import { enableProdMode, importProvidersFrom } from '@angular/core';

import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateTimeService,
  FreshchatWidgetService,
  ImmomioTheme,
  InfrastructureConfig,
  InfrastructureModule,
  ThemeModule,
  ThemeUrls
} from '@ui/legacy-lib';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateFakeLoader,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { LightboxModule } from 'ng-gallery/lightbox';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { provideStore } from '@ngrx/store';

import { provideEffects } from '@ngrx/effects';
import { provideHttpClient } from '@angular/common/http';
import { languageConfig, storageKeys } from './app/config';
import { AppComponent } from './app/app.component';
import { CoreModule } from './app/core';
import { AppRouterModule } from './app/app.router';
import { appReducers, effects } from './app/+state';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const infrastructureConfig: InfrastructureConfig = {
  environment,
  storageKeys,
  languages: languageConfig
};

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      CoreModule,
      BrowserModule,
      AppRouterModule,
      CommonModule,
      FormsModule,
      ReactiveFormsModule.withConfig({
        callSetDisabledState: 'whenDisabledForLegacyCode'
      }),
      InfrastructureModule,
      BrowserAnimationsModule,
      InfrastructureModule.forRoot(infrastructureConfig),
      TranslateModule.forRoot({
        loader: { provide: TranslateLoader, useClass: TranslateFakeLoader }
      }),
      LightboxModule,
      AngularSvgIconModule.forRoot(),
      ThemeModule.forRoot({
        themes: [ImmomioTheme],
        active: ThemeUrls.IMMOMIO
      })
    ),
    provideHttpClient(),
    FreshchatWidgetService,
    DateTimeService,
    provideStore(appReducers),
    provideEffects(effects)
  ]
}).catch(err => console.error(err));
