import {
  fadeOnEnterLeaveAnimation,
  getTranslationLoaded,
  I18nService,
  LoadingSpinnerComponent,
  PageTitleService,
  ThemeComponent,
  ThemeDirective,
  ThemeService,
  ToastService
} from '@ui/legacy-lib';
import {
  AfterViewInit,
  Component,
  inject,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as fromPropertyListState from 'homepage/+state/property-list/property-list.reducers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from 'homepage-env';
import { AsyncPipe } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeOnEnterLeaveAnimation],
  imports: [
    LoadingSpinnerComponent,
    AsyncPipe,
    RouterOutlet,
    ThemeComponent,
    ThemeDirective
  ],
  standalone: true
})
export class AppComponent implements AfterViewInit {
  toastService = inject(ToastService);
  viewContainerRef = inject(ViewContainerRef);
  private pageTitleService = inject(PageTitleService);
  private store = inject(Store);
  private activatedRoute = inject(ActivatedRoute);
  private i18nService = inject(I18nService);
  private themeService = inject(ThemeService);
  private translate = inject(TranslateService);

  public finishedLoaded$: Observable<boolean>;

  public constructor() {
    const viewContainerRef = this.viewContainerRef;

    this.pageTitleService.run(this.activatedRoute);
    this.i18nService.loadDefaultTranslations();
    this.toastService.setRootViewContainerRef(viewContainerRef);
    this.finishedLoaded$ = this.store.select(getTranslationLoaded);
    this.themeService.init(environment);
  }

  ngAfterViewInit(): void {
    this.store
      .select(fromPropertyListState.getError)
      .pipe(untilDestroyed(this))
      .subscribe(error => {
        if (error) {
          this.toastService.error(
            this.translate.instant('general.unexpected_error_occurred_m')
          );
        }
      });
    this.store
      .select(fromPropertyListState.getOptions)
      .pipe(untilDestroyed(this))
      .subscribe(options => {
        if (options) {
          const theme = options.branding.theme;
          const activeTheme = {
            logo: options.branding.logo,
            favicon: options.branding.favicon,
            theme: {
              ...theme
            }
          };
          this.themeService.createTheme(activeTheme);
          this.themeService.setTheme(activeTheme.theme.name);
        }
      });
  }
}
