import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HomepagePropertySearchResponse } from 'homepage/models';
import { MapComponent, MapRadiusDrawInfo } from '@ui/legacy-lib';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as fromPropertyListState from 'homepage/+state/property-list/property-list.reducers';
import { AsyncPipe } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-properties-map',
  templateUrl: './properties-map.component.html',
  styleUrls: ['./properties-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MapComponent, AsyncPipe],
  standalone: true
})
export class PropertiesMapComponent implements OnInit {
  private store = inject(Store);

  @ViewChild(MapComponent) map: MapComponent;
  @Input() properties: HomepagePropertySearchResponse[];
  public tileServerUrl$: Observable<string>;

  public mapRadiusDrawInfo: MapRadiusDrawInfo;

  ngOnInit(): void {
    this.store
      .select(fromPropertyListState.getMapRadiusDrawInfo)
      .pipe(untilDestroyed(this))
      .subscribe(drawInfo => {
        this.mapRadiusDrawInfo = drawInfo;
      });

    this.tileServerUrl$ = this.store.select(
      fromPropertyListState.getTileServerUrl
    );
  }

  public openApplyPage(applicationLink: string) {
    window.open(applicationLink, '_blank');
  }
}
